<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p v-if="editMode" class="title">Edit Newspaper Articles</p>
        <p v-else class="title">Add Newspaper Article</p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submitForm" id="editorForm">
      <div class="columns">
        <div class="column">
          <div class="box">
            <div class="columns">
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title" required></b-input>
                </b-field>

                <b-field
                  label="Author(s)*"
                  message="Enter multiple values separated by comma (,)"
                >
                  <b-input v-model="formData.authors" required></b-input>
                </b-field>
                <b-field label="Subject*">
                  <b-input v-model="formData.subject" required></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Source*">
                  <b-input v-model="formData.source" required></b-input>
                </b-field>
                <b-field
                  label="Keyword(s)*"
                  message="Enter multiple values separated by comma (,)"
                >
                  <b-input v-model="formData.keywords" required></b-input>
                </b-field>
                <b-field label="Publication Date*">
                  <b-datepicker
                    locale="en-GB"
                    v-model="formData.publicationDate"
                    placeholder="Click to select..."
                    icon="calendar-today"
                    trap-focus
                    required
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <hr />
            <div class="has-text-right">
              <b-button @click="cancel" class="button">Cancel</b-button>
              <button
                form="editorForm"
                class="button is-primary ml-4"
                type="submit"
                :disabled="isSubmitting"
              >
                {{ isSubmitting ? "Saving" : "Save" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "Editor",
  data() {
    return {
      isSubmitting: false,
      editMode: false,
      formData: {
        source: undefined,
        publicationDate: undefined,
        subject: undefined,
        keywords: undefined,
        title: undefined,
        authors: undefined
      }
    };
  },
  async created() {
    if (this.$route.params.itemDetail) {
      this.editMode = true;
      for (var key in this.$route.params.itemDetail) {
        if (this.$route.params.itemDetail[key]) {
          this.formData[key] = Array.isArray(this.$route.params.itemDetail[key])
            ? this.$route.params.itemDetail[key].join(", ")
            : this.$route.params.itemDetail[key];
        }
      }
      this.formData.publicationDate = new Date(this.formData.publicationDate);
    }
  },
  computed: {},
  methods: {
    cancel() {
      this.$router.push({ name: "Newspaper" });
    },
    async submitForm() {
      this.isSubmitting = true;
      const request = cloneDeep(this.formData);
      request["keywords"] = request["keywords"].split(",").map(function(item) {
        return item.trim();
      });
      request["authors"] = request["authors"].split(",").map(function(item) {
        return item.trim();
      });
      if (this.editMode) {
        if (
          await this.$store.dispatch("editNewspaper", {
            id: this.$route.params.itemDetail._id,
            request: request
          })
        ) {
          this.$router.push({ name: "Newspaper" });
        }
      } else {
        if (await this.$store.dispatch("addNewspaper", request)) {
          this.$router.push({ name: "Newspaper" });
        }
      }
      this.isSubmitting = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
